<template>
  <section>
    <div class="content-header">
      <h2>Blog posts</h2>
    </div>
    <div class="content-wrapper">
      <ul>
        <li><a href="https://www.yourcareerguide.ca/insights/career-development-is-a-superpower/" target="_blank">Career Development is a Superpower</a></li>
        <li><a href="https://careerwise.ceric.ca/blog/#.YcCWWxPMJuJ" target="_blank">CERIC CareerWise</a></li>
      </ul>
      <!-- <img src="@/assets/img/_content/career-review-1.png" width="400px" height="400px" class="img-fluid d-block mx-auto" alt="" > -->
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '01',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
